import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";

const state = {
  events: [],
  event: null,
  questionnaireQuestions: [],
  doctorExisted: null,
  eventVoucherCode: null,
  doctorInEvent: false,
  todaysEvents: [],
};

const mutations = {
  SET_EVENTS(state, payload) {
    state.events = payload;
  },
  SET_EVENT(state, payload) {
    state.event = payload;
  },
  SET_QUESTIONNAIRE_QUESTIONS(state, payload) {
    state.questionnaireQuestions = payload;
  },
  SET_DOCTOR_EXIST(state, payload) {
    if (payload === "yes") {
      state.doctorExisted = true;
    } else if (payload === "no") {
      state.doctorExisted = false;
    } else {
      state.doctorExisted = null;
    }
  },
  SET_VOUCHER_CODE(state, payload) {
    let num = String(payload).padStart(3, "0");
    state.eventVoucherCode = num;
  },
  SET_DOCTOR_EXIST_IN_EVENT(state, payload) {
    state.doctorInEvent = payload;
  },
  SET_TODAY_EVENTS(state, payload) {
    state.todaysEvents = payload;
  },
};

const actions = {
  saveEvent({ commit, rootGetters }, payload) {
    axios
      .post("/events", payload.data.event, {
        headers: {
          Authorization: "Bearer " + rootGetters.token,
        },
      })
      .then((res) => {
        const msg = "You successfully saved a new event";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        commit("makeToast", toast);
      })
      .catch((rej) => {
        const msg = "There was an error saving your calendar";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  updateEvent({ commit, rootGetters }, payload) {
    axios
      .post("/events/" + payload.id + "/edit", payload.data.event, {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        const msg = "You successfully updated a new event";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        router.push({ name: "Events" });
        commit("makeToast", toast);
      })
      .catch((rej) => {
        const msg = "There was an error updating your calendar";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  getEvents({ commit, rootGetters }) {
    axios
      .get("/events", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        commit("SET_EVENTS", res.data);
      })
      .catch((err) => {});
  },
  getEvent({ commit, rootGetters }, payload) {
    axios
      .get("/events/" + payload.id, {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        commit("SET_EVENT", res.data);
      })
      .catch((err) => {});
  },
  getQuestionnaireQuestions({ commit, rootGetters }, payload) {
    axios
      .get("/events/" + payload.eventId + "/questions", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        commit("SET_QUESTIONNAIRE_QUESTIONS", res.data);
      })
      .catch((err) => {});
  },
  submitQuestionnaire({ state, commit, rootGetters, rootState }, payload) {
    axios
      .post(
        "/doctors/" +
          payload.data.doctorId +
          "/events/" +
          payload.data.eventId +
          "/questionnaire",
        { questions: payload.data.questions },
        { headers: { Authorization: "Bearer " + rootGetters.token } }
      )
      .then((res) => {
        const msg = "You successfully submitted your questionnaire";
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "success",
        };
        payload.vm.resetFields();
        payload.vm.loading = false;
        // payload.vm.send("DONE");

        setTimeout(() => {
          commit("makeToast", toast);
          // console.log("STATE", rootState.doctor.eventDoctor);
          if (state.event.hasPhoto) {
            router.push({
              name: "Select Frame",
              params: {
                eventId: payload.data.eventId,
                doctorId: payload.data.doctorId,
              },
            });
          } else if (state.event.hasVoucher) {
            payload.vm.$bvModal.show("voucher-modal");
          } else {
            router.push({ name: "Thank You" });
          }
        }, 1000);
      })
      .catch((err) => {
        const msg = "There was an error submitting your questionnaire";
        payload.vm.loading = false;
        const toast = {
          vm: payload.vm,
          msg: msg,
          color: "danger",
        };

        commit("makeToast", toast);
      });
  },
  uploadEventPhoto({ commit, rootGetters }, payload) {
    var fd = new FormData(document.forms[0]);
    fd.append("myFile", payload.data.blob);
    axios
      .post(
        "/events/" +
          payload.eventId +
          "/event-photo/doctors/" +
          payload.doctorId,
        fd,
        {
          headers: {
            Authorization: "Bearer " + rootGetters.token,
            "Content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        payload.vm.showPrint = true;
        // payload.vm.stopCapturing();
      })
      .catch((err) => {});
  },
  getEventVoucher({ commit, rootGetters }, payload) {
    axios
      .get("/events/voucher-code/" + payload.eventId, {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => commit("SET_VOUCHER_CODE", res.data))
      .catch((err) => {});
  },
  doctorExistInEvent({ commit, rootGetters }, payload) {
    axios
      .get(
        "/events/" +
          payload.eventId +
          "/doctors/" +
          payload.doctorId +
          "/exist",
        { headers: { Authorization: "Bearer " + rootGetters.token } }
      )
      .then((res) => {
        const data = res.data;

        if (data) {
          commit("SET_DOCTOR_EXIST_IN_EVENT", true);
          commit("SET_EVENT_DOCTOR", data, { root: true });
        } else {
          commit("SET_DOCTOR_EXIST_IN_EVENT", false);
        }
      })
      .catch((err) => {});
  },
  getTodaysEvents({ commit, rootGetters }, payload) {
    axios
      .get("/events/event-exists-today", {
        headers: { Authorization: "Bearer " + rootGetters.token },
      })
      .then((res) => {
        const data = res.data;
        if (data.length > 0) {
          router.push({ name: "Event Details", params: { id: data[0].id } });
        } else {
          router.push({ name: "Events" });
        }

        commit("SET_TODAY_EVENTS", res.data);
      })
      .catch((err) => {});
  },
  printVoucher({ commit, rootGetters }, payload) {
    axios
      .get(
        "/print/voucher/events/" +
          payload.eventId +
          "/doctors/" +
          payload.doctorId,
        {
          headers: {
            Authorization: "Bearer " + rootGetters.token,
          },
        }
      )
      .then((res) => {
        commit("SET_EVENT_DOCTOR", res.data, { root: true });
      })
      .catch((err) => {});
  },
  printImage({ commit, rootGetters }, payload) {
    axios
      .post(
        "/print/image/events/" +
          payload.eventId +
          "/doctors/" +
          payload.doctorId,
        payload.formFile,
        {
          headers: {
            Authorization: "Bearer " + rootGetters.token,
            "Content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        commit("SET_EVENT_DOCTOR", res.data, { root: true });
      })
      .catch((err) => {});
  },
  printForm({ commit, rootGetters }, payload) {
    axios
      .post(
        "/print/form/events/" +
          payload.eventId +
          "/doctors/" +
          payload.doctorId,
        payload.formFile,
        {
          headers: {
            Authorization: "Bearer " + rootGetters.token,
            "Content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        commit("SET_EVENT_DOCTOR", res.data, { root: true });
      })
      .catch((err) => {});
  },
};

const getters = {
  event(state) {
    return state.event;
  },
  events(state) {
    return state.events;
  },
  questionnaireQuestions(state) {
    return state.questionnaireQuestions;
  },
  doctorExisted(state) {
    return state.doctorExisted;
  },
  eventVoucherCode(state) {
    return state.eventVoucherCode;
  },
  doctorInEvent(state) {
    return state.doctorInEvent;
  },
  todaysEvents(state) {
    return state.todaysEvents;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
