import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";

const state = {
  frameSelected: null,
};

const mutations = {
  SET_FRAME(state, payload) {
    state.frameSelected = payload;
  },
};

const actions = {};

const getters = {
  frameSelected(state) {
    return state.frameSelected;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
