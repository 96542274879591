<template>
  <div>
    <b-modal hide-footer id="voucher-modal" title="Recieve your voucher">
      <main id="voucher-body">
        <h1>{{ event ? event.voucherText : "" }}</h1>
        <div class="voucher-data">
          <div class=" info">
            <p>
              Dr: <span>{{ doctor ? doctor.name : "" }}</span>
            </p>
          </div>
          <!-- <div class="info">
            <p>
              Code: <span>{{ eventVoucherCode }}</span>
            </p>
          </div> -->
        </div>
      </main>

      <div class="d-flex justify-content-center flex-column">
        <b-button
          v-if="eventDoctor && !eventDoctor.voucherPrinted"
          @click="printVoucherHandler"
          class=" mt-4 btn-success w-100"
          >Print</b-button
        >
        <b-button @click="close" class=" mt-2 btn-danger w-100">Close</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import html2pdf from "html2pdf.js";

export default {
  created() {
    this.getEventVoucher({ eventId: this.$route.params.eventId });
  },
  props: {
    vm: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({
      getEventVoucher: "getEventVoucher",
      printVoucher: "printVoucher",
    }),
    async printVoucherHandler() {
      this.printVoucher({
        eventId: this.event.id,
        doctorId: this.doctor.id,
      });
    },
    close() {
      this.$router.push({ name: "Thank You" });
      this.$bvModal.hide("voucher-modal");
      this.vm.stopCapturing();
    },
  },
  computed: {
    ...mapGetters({
      doctor: "doctor",
      eventVoucherCode: "eventVoucherCode",
      event: "event",
      eventDoctor: "eventDoctor",
    }),
  },
};
</script>

<style lang="scss" scoped>
#voucher-modal {
  h1 {
    text-align: center;
    font-family: fantasy;
    font-size: 24px;
  }

  .voucher-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .info {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-bottom: 0;
    }
  }

  p {
    font-size: 20px;
    font-weight: 400;
    font-family: fantasy;

    span {
      font-size: 16px;
      font-weight: 300;
    }
  }
}
</style>
