import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";
import SockJS from "sockjs-client";
import Stomp from "webstomp-client";

const state = {
  doctorIsProcessing: false,
  connected: false,
};

const mutations = {
  SET_DOCTOR_IS_PROCESSING(state, payload) {
    state.doctorIsProcessing = payload;
  },
  SET_CONNECTED(state, payload) {
    state.connected = payload;
  },
};

const actions = {
  connect({ commit, state }, payload) {
    // this.socket = new SockJS(
    //   "http://172.81.180.243:8080/Novonordisk2/gs-guide-websocket"
    // );
    this.socket = new SockJS("http://localhost:8080/gs-guide-websocket");
    this.stompClient = Stomp.over(this.socket);
    this.stompClient.connect(
      {},
      (frame) => {
        commit("SET_CONNECTED", true);
        // console.log(frame);
        this.stompClient.subscribe("/topic/questionnaire", (tick) => {
          // console.log(tick);
          // this.received_messages.push(JSON.parse(tick.body).content);
        });
      },
      (error) => {
        // console.log(error);
        commit("SET_CONNECTED", false);
      }
    );
  },

  disconnect({ commit, state }, payload) {
    if (this.stompClient) {
      this.stompClient.disconnect();
    }

    commit("SET_CONNECTED", false);
  },

  send({ commit }, payload) {
    if (this.stompClient && this.stompClient.connected) {
      const msg = { name: payload.msg };
      this.stompClient.send(
        "/app/questionnaire_status",
        JSON.stringify(msg),
        {}
      );
    }
  },
};

const getters = {
  doctorIsProcessing(state) {
    return state.doctorIsProcessing;
  },
  connected(state) {
    return state.connected;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
