import axios from "../../axios-auth";
import router from "../../router";
import Vue from "vue";

const state = {
  questionnaire: [],
};

const mutations = {
  SET_QUESTIONNAIRE(state, payload) {
    state.questionnaire = payload;
  },
};

const actions = {
  getDoctorQuestionniare({ commit, rootGetters }, payload) {
    axios
      .get(
        "/events/" +
          payload.eventId +
          "/doctors/" +
          payload.doctorId +
          "/questionnaire",
        { headers: { Authorization: "Bearer " + rootGetters.token } }
      )
      .then((res) => {
        commit("SET_QUESTIONNAIRE", res.data);
      })
      .catch((err) => {});
  },
};

const getters = {
  questionnaire(state) {
    return state.questionnaire;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
