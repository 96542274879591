// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Select your language",
  },
  MENU: {
    NEW: "new",
    ACTIONS: "Actions",
    CREATE_POST: "Create New Post",
    PAGES: "Pages",
    FEATURES: "Features",
    APPS: "Apps",
    DASHBOARD: "Dashboard",
    EVENTS: "Events",
    ADD_EVENT: "Add Event",
    QUESTIONS: "Questions",
    ADD_QUESTION: "Add Question",
    DOCTORS: "Doctors",
    REPORTS: "Reports",
    ADD_DOCTOR: "Add Doctor",
    ADD_USER: "Add User",
    USERS: "Users",
    HI: "Hi",
  },
  AUTH: {
    GENERAL: {
      OR: "Or",
      SUBMIT_BUTTON: "Submit",
      NO_ACCOUNT: "Don't have an account?",
      SIGNUP_BUTTON: "Sign Up",
      FORGOT_BUTTON: "Forgot Password",
      BACK_BUTTON: "Back",
      PRIVACY: "Privacy",
      LEGAL: "Legal",
      CONTACT: "Contact",
    },
    LOGIN: {
      TITLE: "Login Account",
      BUTTON: "Sign In",
    },
    FORGOT: {
      TITLE: "Forgot Password?",
      DESC: "Enter your email to reset your password",
      SUCCESS: "Your account has been successfully reset.",
    },
    REGISTER: {
      TITLE: "Sign Up",
      DESC: "Enter your details to create your account",
      SUCCESS: "Your account has been successfuly registered.",
    },
    INPUT: {
      EMAIL: "Email",
      FULLNAME: "Fullname",
      PASSWORD: "Password",
      CONFIRM_PASSWORD: "Confirm Password",
      USERNAME: "Username",
    },
    VALIDATION: {
      INVALID: "{{name}} is not valid",
      REQUIRED: "{{name}} is required",
      MIN_LENGTH: "{{name}} minimum length is {{min}}",
      AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
      NOT_FOUND: "The requested {{name}} is not found",
      INVALID_LOGIN: "The login detail is incorrect",
      REQUIRED_FIELD: "Required field",
      MIN_LENGTH_FIELD: "Minimum field length:",
      MAX_LENGTH_FIELD: "Maximum field length:",
      INVALID_FIELD: "Field is not valid",
    },
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Selected records count: ",
      ALL: "All",
      SUSPENDED: "Suspended",
      ACTIVE: "Active",
      FILTER: "Filter",
      BY_STATUS: "by Status",
      BY_TYPE: "by Type",
      BUSINESS: "Business",
      INDIVIDUAL: "Individual",
      SEARCH: "Search",
      IN_ALL_FIELDS: "in all fields",
    },
    ECOMMERCE: "eCommerce",
    CUSTOMERS: {
      CUSTOMERS: "Customers",
      CUSTOMERS_LIST: "Customers list",
      NEW_CUSTOMER: "New Customer",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Customer Delete",
        DESCRIPTION: "Are you sure to permanently delete this customer?",
        WAIT_DESCRIPTION: "Customer is deleting...",
        MESSAGE: "Customer has been deleted",
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Customers Delete",
        DESCRIPTION: "Are you sure to permanently delete selected customers?",
        WAIT_DESCRIPTION: "Customers are deleting...",
        MESSAGE: "Selected customers have been deleted",
      },
      UPDATE_STATUS: {
        TITLE: "Status has been updated for selected customers",
        MESSAGE: "Selected customers status have successfully been updated",
      },
      EDIT: {
        UPDATE_MESSAGE: "Customer has been updated",
        ADD_MESSAGE: "Customer has been created",
      },
    },
  },
  COMMON: {
    SUBMIT: "Submit",
    SHOW_DETAILS: "Show Details",
    ADD: "Add",
    EDIT: "Edit",
    DELETE: "Delete",
    VERIFY: "Verify",
    BACK: "Back",
    YES: "Yes",
    NO: "No",
    SKIP: "Skip",
    WRITE_STH: "Enter something...",
    SELECT_FRAME: "Please Select Frame",
    TAKE_PHOTO: "Please Take a photo of yourself",
    EXTRACT: "Extract",
    THANK_YOU: "Thank you",
  },
  EVENT: {
    ADD_EVENT: "Add Event",
    EDIT_EVENT: "Edit Event",
    EVENT_NAME: "Event Name",
    EVENT_LOCATION: "Event Location",
    EVENT_DATE: "Event Date",
    EVENT_TEXT: "Event Intro Text",
    HAS_FORM: "Has Form",
    HAS_QUESTIONNAIRE: "Has Questionnaire",
    HAS_PHOTO: "Has Photo",
    HAS_VOUCHER: "Has Vocuher",
    EVENTS: "Events",
    IS_DOCTOR_REGISTERED: "Are you registered in J.A.R.V.I.S?",
    FIELDS: [
      { key: "eventName", label: "Event Name" },
      { key: "eventDate", label: "Event Date" },
      { key: "location", label: "Location" },
      { key: "show_details", label: "" },
    ],
    EVENT_DETAILS: {
      ADD_QUESTIONS: "Add Questions",
      SELECT_LABEL_QUESTION: "Start typing to find the question",
      DOCTORS: "Doctors",
      GO_BACK_TO_TABLES: "Go back to tables",
      REGISTER_DOCTOR: "Register Doctor",
      SELECT_LABEL_DOCTOR: "Start Typing to find the doctor",
      ASSIGNED_DOCTORS: "Assigned Doctors",
      REGISTER_NEW_DOCTOR: "Register New Doctor",
      NO_DOCTORS_NOTE: "Currently There're no assigned doctors",
      TAKE_PHOTO: "Please Take a photo",
      RECIEVE_VOUCHER: "Recieve your voucher",
      FORM: {
        NAME: "Name*",
        FIRST_NAME: "First Name*",
        LAST_NAME: "Last Name*",
        EMAIL: "E-mail*",
        ADDRESS: "Address",
        MOBILE_NUMBER: "Mobile Number*",
        SPECIALITY: "Speciality*",
        GOVERNORATE: "Governorate*",
        BEEN_VISITED: "Have you been visited by J.A.R.V.I.S?",
        ENTER_PHONE_NUMBER: "please enter your phone number",
      },
      VERFICATION_MODAL_TITLE: "Please enter verification code",
      ASSIGN_TO_EVENT: "Assign to event",
    },
  },
  QUESTION: {
    ADD_QUESTION: "Add Question",
    QUESTION: "Question",
    TYPE: "Type",
    ANSWER: "Answer",
    OPTIONS: "Options",
    QUESTIONS: "Questions",
    FIELDS: [
      { key: "question", label: "Question" },
      { key: "type", label: "Type" },
      { key: "show_details", label: "Show Answers" },
      { key: "actions", label: "" },
    ],
  },
  DOCTOR: {
    FIELDS: [
      {
        key: "name",
        label: "Name",
      },
      {
        key: "email",
        label: "E-mail",
      },
      {
        key: "speciality",
        label: "Speciality",
      },
      {
        key: "governorate",
        label: "Governorate",
      },
      {
        key: "mobileNo",
        label: "Mobile Number",
      },
      {
        key: "questionnaireSubmitted",
        label: "Questionnare Status",
      },
    ],
    GO_TO_QUESTIONNAIRE: "Go To Questionnaire",
    SUBMITTED: "Submitted",
  },
  USER: {
    ADD_USER: "Add User",
    EDIT_USER: "Edit User",
    USERNAME: "Username",
    EMAIL: "E-mail",
    PASSWORD: "Password",
  },
  REPORTS: {
    EVENT_DETAILS_REPORT: "Event Details Report",
    EVENT_DETAILS_REPORT_PAGE: {
      TITLE: "Event Details Report",
      EVENT: "Event",
      DOCTOR: "Doctor",
      SPECIALITY: "Speciality",
      GOVERNORATE: "Governorate",
      EVENT_PLACEHOLDER: "Please select an event",
      DOCTOR_PLACEHOLDER: "Please select a doctor",
      SPECIALITY_PLACEHOLDER: "Please select a speciality",
      GOVERNORATE_PLACEHOLDER: "Please select a governorate",
      NOT_SUBMITTED: "Not Submitted",
      GO_TO_QUESTIONNAIRE: "Go to Questionnaire",
    },
  },
  PRINT_FORM: {
    PRINT: "Print",
    GO_TO_QUESTIONNAIRE: "Go To Questionniare",
    CONTACT_DETAILS: "Contact Details:",
  },
  FIELDS: {
    NAME: "Name",
    EMAIL: "E-mail",
    ADDRESS: "Address",
    MOBILE_NUMBER: "Mobile Number",
    SPECIALITY: "Speciality",
    GOVERNORATE: "Governorate",
    BEEN_VISITED: "Have you been visited by J.A.R.V.I.S ?",
  },
  DOCTOR_QUESTIONNAIRE: {
    TITLE: "Questionnare",
  },
};
