import axios from "axios";

const instance = axios.create({
  // baseURL: "http://172.81.180.243:8080/Novonordisk2/api/",
  // baseURL: "http://localhost:8080/api/",
  // baseURL: "http://170.75.167.154:8080/Jarvis/api/",
  baseURL: "https://www.jarvis-xavier.com:9090/api/",
});

export default instance;

// baseURL: "http://172.81.180.243:8080/Novonordisk2/api/",
// baseURL: "http://localhost:8080/api/",
